import { ThemeProvider, Global, css, useTheme } from "@emotion/react";
import React from "react";
import { theme } from "../theme";
import AppFooter from "./footer";
import AppNav from "./nav/nav";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MediaContextProvider, mediaStyle } from "../media";

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <MediaContextProvider>
        <Global
          styles={css`
            body {
              font-family: "Poppins";
              margin: 0;
              color: ${theme.colors.background.contrastText};
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              p {
                font-size: 16px;
              }
              a {
                font-weight: 500;
                color: inherit;
              }
            }
            ${mediaStyle}
          `}
        />
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppNav />
          <div
            style={{
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {children}
          </div>
          <AppFooter />
        </div>
      </MediaContextProvider>
    </ThemeProvider>
  );
};

export default Layout;
