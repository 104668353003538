import styled from "@emotion/styled";
import { motion, Variants } from "framer-motion";
import React, { useMemo, useRef, useState } from "react";

type Props = {
  children: React.ReactNode;
  trigger: React.ReactNode;
};

const Dropdown = styled(motion.ul)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  paddingLeft: 0,
  top: theme.spacing(2),
  left: 0,
  boxShadow: theme.shadows(2),
  backgroundColor: theme.colors.background.main,
  flexDirection: "column",
}));

const variants: Variants = {
  enter: {
    opacity: 1,
    translateY: 10,
    rotateX: 0,
    transition: {
      duration: 0.15,
    },
    display: "flex",
  },
  exit: {
    opacity: 0,
    translateY: -10,
    rotateX: -15,
    transition: {
      duration: 0.15,
      delay: 0.1,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const AppNavDropdown: React.FC<Props> = ({ trigger, children }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <motion.div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(true)}
      css={{
        display: "inline",
        position: "relative",
      }}
    >
      {trigger}
      <Dropdown
        ref={ref}
        variants={variants}
        animate={open ? "enter" : "exit"}
        initial="exit"
      >
        {children}
      </Dropdown>
    </motion.div>
  );
};

export default AppNavDropdown;
