import styled from "@emotion/styled";
import React, { useMemo } from "react";
import Ripples from "react-ripples";

export type Props<
  TComponent extends React.ComponentType<any> | keyof JSX.IntrinsicElements
> = React.ComponentProps<TComponent> & {
  component?: TComponent;
};

const StyledRipples = styled(Ripples)(({ theme }) => ({
  padding: `${theme.spacing(1) / 1.5}px ${theme.spacing(2)}px`,
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
}));

const AppButton = <
  TComponent extends React.ComponentType<any> | keyof JSX.IntrinsicElements
>({
  component: Component,
  children,
  ...props
}: Props<TComponent>) => {
  const StyledButton = useMemo(
    () =>
      styled(Component ?? "button")(({ theme }) => ({
        backgroundColor: theme.colors.secondary.main,
        color: theme.colors.secondary.contrastText,
        border: "none",
        outline: "none",
        boxShadow: theme.shadows(1),
        cursor: "pointer",
        textDecoration: "none",
      })),
    [Component]
  );

  return (
    <StyledButton {...props}>
      <StyledRipples>{children}</StyledRipples>
    </StyledButton>
  );
};

export default AppButton;
