import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "@emotion/styled";
import { ThemeContext, useTheme } from "@emotion/react";
import Ripples from "react-ripples";
import AppNavDropdown from "../dropdown";
import { useWindowWidth } from "@react-hook/window-size";
import AppNavDrawer from "./nav-drawer";
import { Media } from "../../media";
import AppSoMe from "../so-me";

export const height = 75;
const width = "100%";

const NavFiller = styled.div({ width, height });

const Nav = styled.nav(({ theme }) => ({
  width,
  height,
  position: "fixed",
  top: 0,
  left: 0,
  display: "flex",
  zIndex: 1,
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows(1),
  backgroundColor: theme.colors.background.main,
  paddingLeft: theme.pagePadMobile - 10,
  paddingRight: theme.pagePadMobile,
  boxSizing: "border-box",
  [theme.mq.desktop]: {
    padding: `0 ${theme.spacing(4)}px`,
  },
}));

const Brand = styled(GatsbyLink)(({ theme }) => ({
  display: "block",
  textDecoration: "none",
  fontSize: 22,
  fontWeight: 600,
  color: "#000",
}));

const LinksMedia = styled(Media)({
  marginRight: "auto",
  display: "flex",
  alignItems: "center",
});

const Links = styled.ul({ padding: 0, marginRight: 20 });

const Link = styled(GatsbyLink)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textDecoration: "none",
  lineHeight: `${height}px`,
  fontWeight: 300,
  transition: `all .15s ${theme.cubicTransition}`,
  whiteSpace: "nowrap",
  "&:hover": {
    color: theme.colors.primary.main,
  },
}));

const AppNav = () => {
  const {
    allMarkdownRemark: { edges: specialities },
  } = useStaticQuery<GatsbyTypes.NavQuery>(graphql`
    query Nav {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "specialities" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <NavFiller />
      <Nav>
        <Ripples
          css={{
            marginRight: "auto",
            padding: 10,
          }}
        >
          <Brand to="/">RWS</Brand>
        </Ripples>
        <LinksMedia greaterThanOrEqual="desktop">
          <Links>
            <Ripples>
              <Link to="/kontoret">Kontoret</Link>
            </Ripples>
            <AppNavDropdown
              trigger={
                <Ripples>
                  <Link to="/">Specialer</Link>
                </Ripples>
              }
            >
              {specialities.flatMap(({ node: { frontmatter } }) =>
                !!frontmatter?.slug
                  ? [
                      <Ripples key={frontmatter.slug}>
                        <Link to={`/forretningsområder/${frontmatter?.slug}`}>
                          {frontmatter?.title}
                        </Link>
                      </Ripples>,
                    ]
                  : []
              )}
            </AppNavDropdown>
            <Ripples>
              <Link to="/info-og-nyheder">Nyheder</Link>
            </Ripples>
            <Ripples>
              <Link to="/handelsbetingelser">Betingelser</Link>
            </Ripples>
            <Ripples>
              <Link to="/kontakt">Kontakt</Link>
            </Ripples>
          </Links>
          <AppSoMe />
        </LinksMedia>
        <Media lessThan="desktop">
          <AppNavDrawer specialities={specialities} />
        </Media>
      </Nav>
    </>
  );
};

export default AppNav;
