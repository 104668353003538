import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { BoxProps, Flex } from "reflexbox/styled-components";
import { breakpoints } from "../theme";

type Props = {
  breakpoint?: keyof typeof breakpoints;
} & BoxProps;

const AppContainer: React.FC<Props> = ({
  children,
  breakpoint,
  color,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Flex
      css={css({
        maxWidth: theme.breakpoints[breakpoint ?? "ultraWide"],
        width: "100%",
        "&&": {
          margin: "auto",
        },
      })}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default AppContainer;
