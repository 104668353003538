import facepaint from "facepaint";
import { Theme } from "@emotion/react";
import { rgba } from "polished";

export const breakpoints = {
  mobile: 0,
  tablet: 767,
  desktop: 1200,
  ultraWide: 1920,
};

const mq: { [key in keyof typeof breakpoints]: string } = Object.entries(
  breakpoints
).reduce(
  (res, [key, breakpoint]) => ({
    ...res,
    [key]: `@media (min-width: ${breakpoint}px)`,
  }),
  {
    mobile: "",
    tablet: "",
    desktop: "",
    ultraWide: "",
  }
);

const spacingValues = new Array(20).fill(1).map((_, i) => i * 15);
const spacing = (spacing: number) => spacingValues[spacing];
const pagePad = spacing(6);
const pagePadMobile = spacing(2);

const shadows = (z: number) =>
  `0px ${1 * z}px ${8 * z * 0.7}px rgba(0,0,0,.16)`;

export interface Color {
  main: string;
  light: string;
  contrastText: string;
}

export const cubicTransition = "cubic-bezier(0.4, 0, 0.2, 1) 0s";

declare module "@emotion/react" {
  export interface Theme {
    colors: {
      primary: Color;
      secondary: Color;
      background: Color;
    };
    breakpoints: typeof breakpoints;
    mq: typeof mq;
    spacing: typeof spacing;
    pagePad: typeof pagePad;
    pagePadMobile: typeof pagePadMobile;
    shadows: typeof shadows;
    cubicTransition: typeof cubicTransition;
  }
}

export const theme: Theme = {
  colors: {
    primary: {
      main: "#A78E4C",
      light: "#B79C58",
      contrastText: "#fff",
    },
    secondary: {
      main: rgba("#182334", 0.67),
      light: "#EAEFF8",
      contrastText: "#fff",
    },
    background: {
      main: "#fff",
      light: "#fff",
      contrastText: "#181818",
    },
  },
  spacing,
  pagePad,
  pagePadMobile,
  breakpoints,
  mq,
  shadows,
  cubicTransition,
};
