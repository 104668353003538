import styled from "@emotion/styled";
import { AnimatePresence, motion, Variants } from "framer-motion";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Twirl } from "hamburger-react";
import { useWindowHeight, useWindowSize } from "@react-hook/window-size";
import { useTheme } from "@emotion/react";
import { Flex } from "reflexbox";
import { darken, rgba } from "polished";
import { Link as GatsbyLink } from "gatsby";
import Ripples from "react-ripples";

interface Props {
  specialities: GatsbyTypes.NavQuery["allMarkdownRemark"]["edges"];
}

const Root = styled(motion.div)({
  height: "100%",
  display: "flex",
  alignItems: "center",
  zIndex: 1000,
});

const HamWrap = styled.div({
  zIndex: 3,
});

const Hamburger = styled(Twirl)({});

const Content = styled(motion.div)({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const Background = styled(motion.div)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: "hidden",
  zIndex: 1,
  background: darken(0.5, rgba(theme.colors.primary.main, 0.6)),
}));

const Drawer = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.colors.background.light,
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 2,
}));

const DrawerContent = styled(motion.div)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const Brand = styled.h1(({ theme }) => ({
  color: theme.colors.primary.main,
  fontSize: "15vw",
}));

const contentVariants: Variants = {
  open: {
    display: "block",
  },
  closed: {
    display: "none",
    transition: {
      delay: 0.8,
    },
  },
};

const drawerContentVariants: Variants = {
  open: {
    display: "flex",
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const backgroundVariants: Variants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
    transition: {
      delay: 0.5,
    },
  },
};

const drawerVariants: Variants = {
  open: (height: number = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 50px) 40px)`,
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at calc(100% - 50px) 40px)",
    transition: {
      delay: 0.3,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const Links = styled.ul({
  listStyle: "none",
  padding: 0,
});

const LinkItem = styled.li({
  display: "block",
  width: "100%",
  height: 50,
});

const Link = styled(GatsbyLink)(({ theme }) => ({
  fontSize: 18,
  textAlign: "center",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  fontWeight: 400,
}));

const StyledRipple = styled(Ripples)({
  width: "100%",
  height: 50,
});

const AppNavDrawer = (props: Props) => {
  const [open, setOpen] = useState(false);
  const height = useWindowHeight();

  const onClick = useCallback(() => setOpen(false), []);

  return (
    <Root animate={open ? "open" : "closed"} custom={height} initial="closed">
      <HamWrap>
        <Hamburger toggled={open} toggle={() => setOpen(!open)} size={20} />
      </HamWrap>
      <AnimatePresence>
        <Content variants={contentVariants}>
          <Background variants={backgroundVariants} />
          <Drawer variants={drawerVariants}>
            <DrawerContent variants={drawerContentVariants}>
              <Brand>RWS</Brand>
              <Links>
                <LinkItem>
                  <StyledRipple>
                    <Link onClick={onClick} to="/kontoret">
                      Kontoret
                    </Link>
                  </StyledRipple>
                </LinkItem>
                <Specialities onClick={onClick} {...props} />
                <LinkItem>
                  <StyledRipple>
                    <Link onClick={onClick} to="/info-og-nyheder">
                      Nyheder
                    </Link>
                  </StyledRipple>
                </LinkItem>
                <LinkItem>
                  <StyledRipple>
                    <Link onClick={onClick} to="/handelsbetingelser">
                      Betingelser
                    </Link>
                  </StyledRipple>
                </LinkItem>
                <LinkItem>
                  <StyledRipple>
                    <Link onClick={onClick} to="/kontakt">
                      Kontakt
                    </Link>
                  </StyledRipple>
                </LinkItem>
              </Links>
            </DrawerContent>
          </Drawer>
        </Content>
      </AnimatePresence>
    </Root>
  );
};

const specialitiesVariants: Variants = {
  open: (color: string) => ({
    height: "auto",
    borderColor: color,
    transition: {
      duration: 0.3,
    },
  }),
  closed: {
    height: 0,
    borderColor: "rgba(0,0,0,0)",
    transition: {
      duration: 0.3,
    },
  },
};

const Specialities = ({
  specialities,
  onClick,
}: Props & { onClick: () => void }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <LinkItem>
        <StyledRipple>
          <button
            onClick={() => setOpen(!open)}
            style={{
              background: "transparent",
              outline: "none",
              border: "none",
              fontSize: 18,
              textAlign: "center",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
              fontWeight: 400,
              color: theme.colors.background.contrastText,
            }}
          >
            Specialer
          </button>
        </StyledRipple>
      </LinkItem>
      <motion.ul
        custom={theme.colors.primary.main}
        variants={specialitiesVariants}
        animate={open ? "open" : "closed"}
        style={{
          listStyle: "none",
          padding: 0,
          overflow: "hidden",
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderTopStyle: "solid",
          borderBottomStyle: "solid",
        }}
      >
        {specialities.flatMap(({ node: { frontmatter } }) =>
          !!frontmatter?.slug
            ? [
                <LinkItem key={frontmatter?.slug}>
                  <StyledRipple key={frontmatter.slug}>
                    <Link
                      onClick={onClick}
                      style={{ fontWeight: 300 }}
                      to={`/forretningsområder/${frontmatter?.slug}`}
                    >
                      {frontmatter?.title}
                    </Link>
                  </StyledRipple>
                </LinkItem>,
              ]
            : []
        )}
      </motion.ul>
    </>
  );
};

export default AppNavDrawer;
