// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-handelsbetingelser-tsx": () => import("./../../../src/pages/handelsbetingelser.tsx" /* webpackChunkName: "component---src-pages-handelsbetingelser-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-og-nyheder-index-tsx": () => import("./../../../src/pages/info-og-nyheder/index.tsx" /* webpackChunkName: "component---src-pages-info-og-nyheder-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kontoret-tsx": () => import("./../../../src/pages/kontoret.tsx" /* webpackChunkName: "component---src-pages-kontoret-tsx" */),
  "component---src-templates-news-item-template-tsx": () => import("./../../../src/templates/news-item.template.tsx" /* webpackChunkName: "component---src-templates-news-item-template-tsx" */),
  "component---src-templates-speciality-template-tsx": () => import("./../../../src/templates/speciality.template.tsx" /* webpackChunkName: "component---src-templates-speciality-template-tsx" */)
}

