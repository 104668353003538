import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import rgba from "polished/lib/color/rgba";
import React from "react";
import { Flex } from "reflexbox";
import AppButton from "./button";
import AppContainer from "./container";
import AppSoMe from "./so-me";

const NAV_INFO_QUERY = graphql`
  query NavInfo {
    markdownRemark(fileAbsolutePath: { regex: "/information.md/" }) {
      frontmatter {
        phone
        email
        cvr
        address
      }
    }
  }
`;

const Root = styled.footer(({ theme }) => ({
  height: "auto",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `0 ${theme.pagePadMobile}px`,
  boxSizing: "border-box",
  paddingBottom: theme.spacing(1),
  background: `linear-gradient(180deg, ${theme.colors.primary.light} 0%, ${theme.colors.primary.main} 100%)`,
  [theme.mq.desktop]: {
    padding: `0 ${theme.pagePad}px`,
    height: 180,
    paddingBottom: 0,
  },
}));

const Content = styled(AppContainer)(({ theme }) => ({
  flexDirection: "column",
  [theme.mq.desktop]: {
    flexDirection: "row",
  },
}));

const Brand = styled.strong(({ theme }) => ({
  fontSize: 50,
  color: theme.colors.secondary.contrastText,
  [theme.mq.desktop]: {
    marginRight: theme.spacing(1),
  },
}));

const Info = styled.span(({ theme }) => ({
  display: "block",
  color: rgba(theme.colors.secondary.contrastText, 0.7),
  textAlign: "center",
  marginBottom: theme.spacing(1),
  [theme.mq.desktop]: {
    textAlign: "left",
    marginBottom: 0,
  },
}));

const StyledButton = styled(AppButton)(({ theme }) => ({
  width: "70%",
  display: "block",
  marginTop: theme.spacing(1),
  [theme.mq.desktop]: {
    marginTop: 0,
    width: "auto",
    maxWidth: 200,
    marginRight: 15,
  },
}));

const StyledSoMe = styled(AppSoMe)(({ theme }) => ({
  [theme.mq.desktop]: {
    "&&": {
      marginRight: theme.spacing(2) / 1.5,
    },
  },
}));

const AppFooter = () => {
  const { markdownRemark: data } =
    useStaticQuery<GatsbyTypes.NavInfoQuery>(NAV_INFO_QUERY);

  return (
    <Root>
      <Content alignItems="center" justifyContent="space-between">
        <Brand>RWS</Brand>
        <Flex flexDirection="column" flex={1}>
          <Info>Advokatkontoret Rikke Wagenblast Sørensen</Info>
          <Info>
            Cvr: {data?.frontmatter?.cvr}, {data?.frontmatter?.address}
          </Info>
        </Flex>
        <StyledSoMe footer />
        <StyledButton component="a" href={`tel:${data?.frontmatter?.phone}`}>
          {data?.frontmatter?.phone}
        </StyledButton>
        <StyledButton component="a" href={`mailto:${data?.frontmatter?.email}`}>
          {data?.frontmatter?.email}
        </StyledButton>
      </Content>
    </Root>
  );
};

export default AppFooter;
