import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Flex } from "reflexbox";
import Ripples from "react-ripples";
import { useTheme } from "@emotion/react";

const SO_ME_QUERY = graphql`
  query SoMe {
    markdownRemark(fileAbsolutePath: { regex: "/information.md/" }) {
      frontmatter {
        linkedin
        trustpilot
      }
    }
  }
`;

type IconProps = {
  small: boolean;
};

const LinkedIn = ({ small }: IconProps) => {
  const theme = useTheme();
  const color = theme.colors.secondary.main;

  return (
    <svg
      width={small ? 35 : 47}
      height={small ? 35 : 47}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" stroke={color} />
      <g clipPath="url(#clip0_125:201)">
        <path
          d="M13.201 30.5557H8.09477V14.1119H13.201V30.5557ZM10.6448 11.8688C9.01222 11.8688 7.6875 10.5159 7.6875 8.88334C7.6875 8.09901 7.99907 7.34681 8.55368 6.7922C9.10828 6.2376 9.86049 5.92603 10.6448 5.92603C11.4291 5.92603 12.1813 6.2376 12.736 6.7922C13.2906 7.34681 13.6021 8.09901 13.6021 8.88334C13.6021 10.5159 12.2774 11.8688 10.6448 11.8688ZM32.3118 30.5557H27.217V22.551C27.217 20.6431 27.1783 18.1969 24.5623 18.1969C21.9076 18.1969 21.5003 20.2693 21.5003 22.4138V30.5557H16.3994V14.1119H21.2962V16.3549H21.3675C22.0492 15.0627 23.7144 13.6993 26.1984 13.6993C31.3662 13.6993 32.3162 17.1026 32.3162 21.5227V30.5557H32.3118Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_125:201">
          <rect
            width="28.1481"
            height="28.1481"
            fill="white"
            transform="translate(5.92773 5.92603)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const TrustPilot = ({ small }: IconProps) => {
  const theme = useTheme();
  const color = theme.colors.secondary.main;

  return (
    <svg
      width={small ? 35 : 47}
      height={small ? 35 : 47}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_125:196)">
        <path
          d="M35.1838 16.7596H23.73L20.192 6.25L16.6432 16.7596L5.18945 16.7491L14.4653 23.2509L10.9162 33.75L20.192 27.2588L29.4567 33.75L25.9187 23.2509L35.1838 16.7596Z"
          stroke={color}
        />
        <path
          d="M26.7142 25.6278L25.9181 23.2507L20.1914 27.2587L26.7142 25.6278Z"
          stroke={color}
        />
      </g>
      <rect x="0.685547" y="0.5" width="39" height="39" stroke={color} />
      <defs>
        <clipPath id="clip0_125:196">
          <rect
            width="30"
            height="27.5"
            fill="white"
            transform="translate(5.18555 6.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Link = styled.a(({ theme }) => ({
  display: "inherit",
  "&:first-of-type": {
    marginRight: theme.spacing(2) / 1.5,
  },
}));

type Props = {
  footer?: boolean;
  className?: string;
};

const AppSoMe = ({ footer, className }: Props) => {
  const { markdownRemark: data } =
    useStaticQuery<GatsbyTypes.SoMeQuery>(SO_ME_QUERY);

  return (
    <Flex alignItems="center" className={className}>
      <Link href={data?.frontmatter?.linkedin} target="_blank">
        <Ripples>
          <LinkedIn small={!footer} />
        </Ripples>
      </Link>
      <Link href={data?.frontmatter?.trustpilot} target="_blank">
        <Ripples>
          <TrustPilot small={!footer} />
        </Ripples>
      </Link>
    </Flex>
  );
};

export default AppSoMe;
